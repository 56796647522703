import rootReducer from './reducer';
import { configureStore } from '@reduxjs/toolkit';
import { AppActionTypes } from './actionTypes';

const store = configureStore({
    reducer: rootReducer,
});

export default store;
export { AppActionTypes };
