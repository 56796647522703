import { LocalizationContext } from 'app/Components/Layout/Hooks/useLocalizationContext';
import { LanguageType } from 'app/Models/Constants/LanguageTypes';
import { useContext } from 'react';

export const Beramming = 'Beramming';
export const Feedback = 'Feedback';
export const FindCourt = 'FindCourt';
export const Footer = 'Footer';
export const General = 'General';
export const Header = 'Header';
export const Judicial = 'Judicial';
export const OldDomstol = 'OldDomstol';
export const Search = 'Search';
export const SingleCourt = 'SingleCourt';
export const News = 'News';
export const TU = 'TU';

export const useTranslation = () => {
    const localization = useContext(LocalizationContext);

    const t = (key: string, language: string, fallback: string = ''): string => {
        if (!localization || !key || !language) return fallback;

        const keys = key.split('.');
        let result: any = localization[language as LanguageType];

        for (const k of keys) {
            if (!result) return fallback;
            result = result[k];
        }

        return result || fallback;
    };

    return { t };
};
