import { AppActionTypes } from './actionTypes';

const initialState = {
    isMenuOpen: false,
    menuIds: <any>[],
    menuWithFocus: '',
};

export default function appReducer(state = initialState, action: any) {
    switch (action.type) {
        case AppActionTypes.TOGGLE_MENU_MOBILE:
            return {
                ...state,
                isMenuOpen: action.payload,
            };
        case AppActionTypes.SET_MENU_IDS:
            return {
                ...state,
                menuIds: action.payload,
            };
        case AppActionTypes.SET_MENU_WITH_FOCUS:
            return {
                ...state,
                menuWithFocus: action.payload,
            };
        default:
            return state;
    }
}
