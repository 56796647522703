interface JsonObject {
    [key: string]: any;
}

export function JsonToCamel(o: JsonObject): JsonObject {
    let newO: JsonObject, origKey, newKey, value;

    if (o instanceof Array) {
        return o.map(function (value) {
            if (typeof value === 'object' && value !== null) {
                return JsonToCamel(value);
            }
            return value;
        });
    } else {
        newO = {};
        for (origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
                value = o[origKey];

                if (Array.isArray(value) || (value !== null && typeof value === 'object')) {
                    value = JsonToCamel(value);
                }

                newO[newKey] = value;
            }
        }
    }

    return newO;
}
